define([
        'modules/upx/models/model',
        './structs/TableOrder'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'TableOrder',
            finalizeEmpty: function (parameters, ajaxOptions) {
                return this.callObjectMethod('finalizeEmptyTableOrder',parameters, ajaxOptions);
            },
            finalize: function (parameters, ajaxOptions) {
                return this.callObjectMethod('finalizeTableOrder',parameters, ajaxOptions);
            }
        });
    }
);
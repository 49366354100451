define([
        'modules/upx/models/model',
        './structs/TableOrderPart'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',

            object: 'TableOrderPart',
            newWithReturn: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newTableOrderPartWithReturn',parameters, ajaxOptions);
            },
            incrementPrintCount: function (parameters, ajaxOptions) {
                return this.callObjectMethod('incrementTableOrderPartPrintCount',parameters, ajaxOptions);
            }
        });
    }
);
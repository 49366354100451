define([
        'modules/upx/models/model',
        './structs/Table'
    ],
    function (UpxModel, Struct) {
        return UpxModel.extend({
            struct: Struct,
            module: 'ShopModule',
            idAttribute: 'id',
            object: 'Table',
            newMultiple: function (parameters, ajaxOptions) {
                return this.callObjectMethod('newMultipleTable',parameters, ajaxOptions);
            },
            updateMultiple: function (parameters, ajaxOptions) {
                return this.callObjectMethod('updateMultipleTable',parameters, ajaxOptions);
            }
        });
    }
);